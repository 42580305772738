<template>
  <v-container fluid>
    <Loader v-model="loading" />
    <PrinterDialog v-model="printer" :impresoras="impresoras" />
    <div v-if="tipo.valor == 1">
      <v-row no-gutters>
        <v-col>
          <v-combobox
            :readonly="disabledView"
            item-text="strTelefono"
            item-value="strTelefono"
            :items="clientes.data"
            placeholder="Buscar..."
            label="Telefono"
            outlined
            rounded
            :return-object="false"
            dense
            v-model="localValue.strTelefono"
            append-outer-icon="mdi-account-search"
            @click:append-outer="buscar()"
            
            
          >
          </v-combobox>
       
      
      </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
        <v-select
            dense
            :items="repartidores"
            outlined
            rounded
            item-text="strNombreCompleto"
            item-value="intEmpleado"
            label="Repartidor"
            v-model="localValue.intEmpleado"
            v-if="this.POSEnc.intEstatus > 1"
          >
          </v-select
        ></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabledView"
            dense
            rounded
            outlined
            v-model="localValue.strNombre"
            label="Nombre"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabledView"
            dense
            rounded
            outlined
            v-model="localValue.strDireccion"
            label="Calle y Numero"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-autocomplete
          :readonly="disabledView"
              v-model="localValue.strColonia"
              :items="colonias"
              item-text="strColonia"
              item-value="strColonia"
              label="Colonia"
              outlined
              rounded
              :return-object="false"
              dense
              no-data-text="No existe Colonia"
            >
          </v-autocomplete>
          <template slot="no-data">
             My no data message
          </template>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabledView"
            dense
            rounded
            outlined
            v-model="localValue.strEntreCalle"
            label="Entre Calles"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabledView"
            dense
            rounded
            outlined
            v-model="localValue.strReferencia"
            label="Referencias"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabledView"
            dense
            outlined
            rounded
            v-model="localValue.strPoblacion"
            label="Municipio"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabledView"
            dense
            rounded
            outlined
            v-model="localValue.strTelefono2"
            label="EXT, Telefono 2"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-if="tipo.valor == 2" >
      <v-row no-gutters>
        <v-col
          ><v-text-field
           :readonly="disabled"
            dense
            outlined
            rounded
            v-model="localValue.strRFC"
            label="RFC"
            append-outer-icon="mdi-account-search"
            @click:append-outer="buscarRFC()"
          >
          </v-text-field
        ></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabled"
            dense
            rounded
            outlined
            v-model="localValue.strNombre"
            label="Nombre"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabled"
            dense
            rounded
            outlined
            v-model="localValue.strDireccion"
            label="Calle"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabled"
            dense
            rounded
            outlined
            v-model="localValue.strNumeroExterior"
            label="# Ext"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :readonly="disabled"
            dense
            rounded
            outlined
            v-model="localValue.strNumeroInterior"
            label="# Int"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters v-show="rowColonia">
        <v-col>
          <v-combobox
            :readonly="disabled"
            item-text="strColonia"
            item-value="strColonia"
            :items="colonias"
            placeholder="Buscar..."
            label="Colonia"
            outlined
            rounded
            :return-object="false"
            dense
            v-model="localValue.strColonia"
          >
          </v-combobox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabled"
            dense
            outlined
            rounded
            v-model="localValue.strPoblacion"
            label="Municipio"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabled"
            dense
            rounded
            outlined
            v-model="localValue.strTelefono"
            label="Telefono"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            :readonly="disabled"
            dense
            rounded
            outlined
            v-model="localValue.strCorreoElectronico"
            label="Correo"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-show="divBotones">
      <v-row v-if="!disabledView" no-gutters>
      <v-col>
        <v-btn rounded color="primary" block @click="save()">Guardar</v-btn>
      </v-col>
    </v-row>
    <br>
    <v-row v-if="disabled" no-gutters>
      <v-col>
        <v-btn rounded color="primary" block @click="saleRepa()">Sale Repa</v-btn>
      </v-col>
    </v-row>
    </div>
    
    <BuscarCliente
      :version="tipo.valor"
      :colonias="colonias"
      @clienteSeleccionado="seleccionarCliente"
      v-model="buscarCliente"
    />
  </v-container>
</template>

<script>
import { Session, Toast, ErrorWeb,Dialog,Data } from "../controllers/DataController.js";
import Loader from "./Loader.vue";
import { POSEncFunctions,POSLogImpresion,POSCliente } from "../controllers/POSController.js";
import BuscarCliente from "./BuscarClienteComponent.vue";
import PrinterDialog from "./PrinterDialogComponent.vue";

export default {
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    repartidores: {
      type: Array,
      default: () => [],
    },
    colonias: {
      type: Array,
      default: () => [],
    },
    tipo: {
      type: Object,
      default: () => {},
    },
    orden: {
      default: 0,
    },
    impresoras: {
      type: Array,
      default: () => [],
    },
    POSInfo: {
      type: Object,
      default: () => {},
    },
    
  },
  data() {
    return {
      session: new Session(),
      dataController: new Data(),
      loading: false,
      localValue: this.value,
      POSEnc:this.orden,
      ordenFunctions: new POSEncFunctions(this.$config.api_backend_route),
      buscarCliente: {
        isVisible: false,
        data: [],
      },
      config: this.$config.opciones.find((c) => c.id == 1),
      printer: new Dialog(0, 0, "", "", ""),
      log: new POSLogImpresion(new Session(), this.$config.api_backend_route),
      // POSCliente: {}
      POSCliente: new POSCliente(),
      clientes: [],
      items:[],
      search: null,
      colonia:this.colonias,
      rowColonia:true,
      divBotones:true,
      config_17:this.$config.opciones.find((c) => c.id == 17).valor,
      showRepartidor:false,
      disabledView:true
      
   
    };
  },
  components: {
    Loader,
    BuscarCliente,
    PrinterDialog,
  },
  
  watch: {

    
    'POSEnc.intEstatus': 'checkStatus',

    
     
    search (val) {
      val && this.querySelections(val)
    },
    orden(newValue) {
      this.POSEnc=newValue
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    loading(newValue) {
      this.$emit("loading", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
    colonias(newValue) {
      this.colonia = newValue;
    },
    async ["localValue.strTelefono"](newVal) {
      console.log(newVal)
      console.log(this.tipo)
      if(newVal && this.tipo.valor == 1)
      {
        let data = await this.ordenFunctions.dataclientes(newVal);
        console.log(data)
         if(data != undefined){
          
          this.localValue.intCliente= data.intCliente;
          this.localValue.strColonia= data.strColonia;
          this.localValue.strDireccion= data.strDireccion;
          this.localValue.strEntreCalle= data.strEntreCalle;
          this.localValue.strNombre= data.strNombre;
          this.localValue.strNumeroExterior= data.strNumeroExterior;
          this.localValue.strNumeroInterior= data.strNumeroInterior;
          this.localValue.strPoblacion= data.strPoblacion;
          this.localValue.strReferencia= data.strReferencia;
          this.localValue.strTelefono= data.strTelefono;
          this.localValue.strRFC= data.strRFC;
        }
        else 
        {
          console.log("entro")
          this.localValue.intCliente=0;
          this.localValue.strColonia= "";
          this.localValue.strDireccion= "";
          this.localValue.strEntreCalle= "";
          this.localValue.strNombre= "";
          this.localValue.strNumeroExterior= "";
          this.localValue.strNumeroInterior="";
          this.localValue.strPoblacion= "";
          this.localValue.strReferencia= "";
        }
      }
    
     
    },

    async ["localValue.strRFC"](newVal) {
      if(newVal && this.tipo.valor == 2)
      {
        let data = await this.ordenFunctions.buscarClienteRFC(newVal );
         if(data != undefined){
          
          this.localValue.intCliente= data.intCliente;
          this.localValue.strColonia= data.strColonia;
          this.localValue.strDireccion= data.strDireccion;
          this.localValue.strEntreCalle= data.strEntreCalle;
          this.localValue.strNombre= data.strNombre;
          this.localValue.strNumeroExterior= data.strNumeroExterior;
          this.localValue.strNumeroInterior= data.strNumeroInterior;
          this.localValue.strPoblacion= data.strPoblacion;
          this.localValue.strReferencia= data.strReferencia;
          this.localValue.strRFC= data.strRFC;
        }
        else 
        {
          this.localValue.intCliente="";
          this.localValue.strColonia= "";
          this.localValue.strDireccion= "";
          this.localValue.strEntreCalle= "";
          this.localValue.strNombre= "";
          this.localValue.strNumeroExterior= "";
          this.localValue.strNumeroInterior="";
          this.localValue.strPoblacion= "";
          this.localValue.strReferencia= "";
          this.localValue.strRFC="";
        }
      }
     
     
    },

    async ["localValue.strColonia"](newVal) {
      if(newVal)
      {
        console.log(this.config_17)
        if(this.config_17){
          this.POSEnc.dblEnvio1 = this.colonia.find(element => element.strColonia == newVal).dblEnvio;
          console.log(this.POSEnc.dblEnvio1 )
          //this.POSEnc.dblEnvio1 = 0;
        }
        else {
          this.POSEnc.dblEnvio1 = 0;
         // this.POSEnc.dblEnvio1 = this.colonia.find(element => element.strColonia == newVal).dblEnvio;
        }

      }
    },
  },
  async mounted(){
    console.log(this.POSEnc)
      this.clientes.data = await this.ordenFunctions.clientes(
      this.localValue.strTelefono );

      //oculta div de colonia y botones de guardar
      let config_21 = this.$config.opciones.find((c) => c.id == 21);
      if (config_21.valor) {
        this.rowColonia = false;
        this.divBotones=false;
      } else {
        this.rowColonia = true;
        this.divBotones=true
      }
      await this.checkStatus();
  
  },

  methods: {
    async checkStatus() {
      try {
        // Verifica que this.$config.opciones esté definido
        if (!this.$config || !Array.isArray(this.$config.opciones)) {
          console.error('Config opciones no está definido o no es un array');
          return;
        }

        // Busca la configuración con id 31
        let config_31 = this.$config.opciones.find(c => c.id === 31);

        // Verifica si config_31 está definido y cumple la condición
        if (config_31 && this.POSEnc.intEstatus >= config_31.valor) {
          this.disabledView = true;
          console.log('disabledView es ture', this.POSEnc.intEstatus );
        } else {
          this.disabledView = false;
          console.log('disabledView es false', this.POSEnc.intEstatus );
        }
      } catch (error) {
        // Manejo de errores
        console.error('Error en checkStatus:', error);
      }
    },
  
    
  
    querySelections (v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.clientes.data.filter(e => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
    async save() {
      this.loading = true;
      try {

        if (this.localValue.intCliente > 0) {
          // if( this.localValue.intCliente > 0 && (data != undefined || data == "")){
          await this.ordenFunctions.editarCliente(
            this.localValue,
            this.session.empresa,
            this.session.usuario,
            this.session.terminal
          );
          this.$toast.success("Cliente actualizado con exito", new Toast());
        } else {
          console.log("save cliente",this.localValue.intCliente )
          if(this.tipo.valor == 1){
            if(this.localValue.strColonia== "")
              throw "Favor de seleccionar colonia";
            if(this.localValue.strTelefono== "")
              throw "Favor de agregar Telefono";
            if(this.localValue.strNombre== "")
              throw "Favor de agregar Nombre";
            if(this.localValue.strDireccion== "")
              throw "Favor de agregar Direccion";
            if(this.localValue.strEntreCalle== "")
             throw "Favor de agregar Entre Calle";
          }

          let response = await this.ordenFunctions.saveCliente(
            this.localValue,
            this.session.empresa,
            this.session.usuario,
            this.session.terminal
          );
          this.localValue.intCliente = response.intCliente;
          this.$toast.success("Cliente agregado con exito", new Toast());
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async saleRepa() {
      if(this.localValue.intEmpleado > 0 ) {
        let repa=this.repartidores.find(x => x.intEmpleado == this.localValue.intEmpleado)
        this.localValue.strRepartidor=repa.strNombreCompleto;
        this.localValue.dblDomicilioPagaCon=this.POSEnc.dblPagaCon;
        this.$emit("saleRepa", this.localValue);
     
      }
      else   
        this.$toast.error("Favor de asignar repartidor", new Toast());

     
        
    },
    async buscar() {
      if (!this.disabledView) {
        this.loading = true;
        try {
          console.log(this.localValue.strTelefono)
          if (this.localValue.strTelefono == "")
            this.localValue.strTelefono = this.session.empresa;

          this.buscarCliente.data = await this.ordenFunctions.clientes(
            this.localValue.strTelefono
          );
          console.log(this.buscarCliente.data)
          this.buscarCliente.isVisible = true;
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    async buscarRFC() {
      if (!this.disabled) {
        this.loading = true;
        try {
          this.buscarCliente.data = await this.ordenFunctions.buscarClienteRFC(
            this.localValue.strRFC
          );
          this.buscarCliente.isVisible = true;
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    seleccionarCliente(cliente) {
      this.buscarCliente.isVisible = false;
      this.localValue = cliente;
    },
  
    convertDateJSON(POSEnc) {
      if (POSEnc.datFecha.toString().includes("T")) {
        return POSEnc.datFecha;
      } else {
        let x = POSEnc.datFecha.split(" ");
        let y = x[0].split("/");
        let response = y[2] + "-" + y[1] + "-" + y[0] + "T" + x[1] + ".511Z";
        return response;
      }
    },
  },


  
};
</script>

<style></style>
